import React from 'react'
import * as routes from 'constants/routes'
import { navigate } from 'gatsby'

import './auth.scss'
import SignInForm from 'components/Auth/SignIn'
// import Layout from 'components/shared/Layout'
import Layout from 'layouts/Page'
import SEO from 'components/SEO/SEO'

class LoginPage extends React.Component {
  componentDidMount() {
    // console.log(this.props)
  }

  getRedirectURL = () => {
    let state,
      redirectURL = routes.SEARCH
    if ((state = this.props.location.state)) {
      redirectURL = state.redirectURL || redirectURL
    }
    return redirectURL
  }

  onSubmit = async (email, password) => {
    try {
      await this.props.auth.doSignInWithEmailAndPassword(email, password)
      navigate(this.getRedirectURL(), {
        replace: true,
        state: { ...(this.props.location.state || {}) },
      })
    } catch (error) {
      return error
    }
  }

  render() {
    return (
      <div id="login-page">
        <div className="content-wrap">
          <section className="form-title page-title">
            <h2>Sign In</h2>
          </section>
          <SignInForm onSubmit={this.onSubmit} />
        </div>
      </div>
    )
  }
}

export default ({ ...props }) => (
  <Layout
    {...props}
    navbarProps={{ showSearchBar: false }}
    displayFooter={false}
  >
    <SEO title="Login" />
    <LoginPage />
  </Layout>
)
