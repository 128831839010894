import React, { Component } from 'react'
import styled from '@emotion/styled'
import { css } from '@emotion/core'
import { navigate } from 'gatsby'
import Link from 'gatsby-link'

import CustomAlert from 'components/shared/Forms/CustomAlert'
import { button } from 'utils/styles'

const AuthBtn = styled('button')`
  display: flex;
  flex: 1;
  ${button.purple};
  ${button.big};
`

const INITIAL_STATE = {
  email: '',
  password: '',
  showError: false,
  error: null,
}

export default class SignInForm extends Component {
  state = {
    ...INITIAL_STATE,
  }

  onSubmit = event => {
    event.preventDefault()

    const { email, password } = this.state
    ;(async () => {
      const error = await this.props.onSubmit(email, password)
      if (error) {
        this.setState({ error, showError: true })
      }
    })()
  }

  onChange = event => {
    this.setState({ [event.target.name]: event.target.value })
  }

  render() {
    const { email, password, error, showError } = this.state

    const isInvalid = password === '' || email === ''
    return (
      <form
        role="login-form"
        className="sigin-form"
        id="login-form"
        onSubmit={this.onSubmit}
      >
        <section className="form-page-container">
          <div className="auth-page-subcontainer">
            <div className="auth-page-flex">
              <fieldset className="fieldset fieldset-with-label">
                <label htmlFor="email">Email</label>
                <input
                  type="email"
                  name="email"
                  autoFocus
                  required
                  id="email"
                  maxLength="250"
                  value={email}
                  onChange={this.onChange}
                />
              </fieldset>

              <fieldset className="fieldset fieldset-with-label">
                <label htmlFor="password">Password</label>
                <input
                  type="password"
                  name="password"
                  required
                  id="password"
                  value={password}
                  onChange={this.onChange}
                />
              </fieldset>
              <CustomAlert
                type="error"
                visible={showError}
                message="Incorrect password or email."
              />
              <section className="auth-page-submit">
                <AuthBtn type="submit" value="Submit">
                  Sign In
                </AuthBtn>
              </section>
            </div>
            {/* TODO: Pass state around or store it? */}
            <Link
              to="/users/password_recover/"
              className="forgot_password-text"
            >
              Forgot your password?
            </Link>
            <div>
              <span>Don't have an account yet? </span>
              <Link to="/users/sign_up">Sign Up</Link>
            </div>
          </div>
        </section>
      </form>
    )
  }
}
